import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M51.9,45.7c-0.6,0.3-1.8,0.1-3.6,0.8c-1.1,0.4-2.5,1.2-3.2,1.8c-0.9,0.7-1.5,1.6-2.1,2.6 c-1.7,2.8-2.4,5.9-2.5,6.2c0.3,0,1.9,0.2,3.8-0.4c1.5-0.5,3.3-1.5,4.6-2.8c1.2-1.2,2.1-2.7,2.6-4.4C51.9,48,51.9,46.3,51.9,45.7z M35.1,51c-0.6-0.9-1.3-1.9-2.1-2.6c-0.7-0.6-2.1-1.5-3.2-1.8c-1.9-0.7-3-0.5-3.6-0.8c0,0.6,0,2.3,0.5,3.9 c0.5,1.6,1.4,3.2,2.6,4.4c1.3,1.3,3.1,2.3,4.6,2.8c1.9,0.6,3.5,0.4,3.8,0.4C37.5,56.9,36.9,53.8,35.1,51z M53.7,43.7 c0,2.3-0.6,5.4-1,6.6c-0.2,0.5-1,2.3-1.9,3.9c-1,1.8-2.1,3.4-2.1,3.4l-3.1,4.3c0,0-2.1,2.7-4.4,3.8c-0.7,0.3-1.5,0.5-2.2,0.5 c-0.8,0-1.6-0.2-2.3-0.5c-2.3-1.2-4.4-3.8-4.4-3.8l-3.1-4.3c0,0-1.1-1.6-2.1-3.4c-0.9-1.6-1.7-3.4-1.9-3.9c-0.3-1.1-0.9-4.3-1-6.6 c0-1.5,0.2-3.8,0.6-5.8c0.2-1,0.4-2,0.5-2.7c0.3-1.3,1.1-3.5,2.2-5.3c0.7-1.2,1.6-2.1,2.5-2.9c1.1-0.8,2.5-1.7,4-2.2 c0.7-0.3,1.5-0.5,2.1-0.6c1.8-0.4,4-0.4,5.7,0c0.7,0.2,1.4,0.4,2.1,0.6c1.5,0.6,2.9,1.4,4,2.2c0.9,0.7,1.8,1.6,2.5,2.9 c1.1,1.8,1.9,4,2.2,5.3c0.2,0.7,0.4,1.7,0.5,2.7C53.5,39.9,53.7,42.2,53.7,43.7"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
