import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.4 187.35">
    <g id="B">
      <path
        fill="currentColor"
        d="M118.2,95.76c-1.8,1-5.2.4-10.5,2.3-3.1,1.1-7.2,3.5-9.3,5.3-2.5,2.1-4.5,4.8-6.1,7.5-5,8.2-6.8,17-7.2,18,1,.1,5.6.5,11-1.1,4.4-1.3,9.4-4.3,13.2-8,3.5-3.4,6.2-7.9,7.6-12.6,1.4-4.9,1.4-9.8,1.3-11.4ZM70,110.86c-1.7-2.7-3.6-5.3-6.1-7.5-2.1-1.8-6.2-4.2-9.3-5.3-5.3-1.9-8.7-1.4-10.5-2.3-.1,1.6-.1,6.5,1.3,11.4,1.4,4.7,4.1,9.2,7.6,12.6,3.8,3.7,8.8,6.6,13.2,8,5.4,1.6,10,1.2,11,1.1-.4-1-2.2-9.8-7.2-18ZM123.6,89.86c-.1,6.5-1.8,15.6-2.8,18.9-.5,1.5-2.8,6.7-5.4,11.3-2.9,5.2-6.2,9.8-6.2,9.8l-8.8,12.3s-6,7.7-12.8,11c-2,.9-4.2,1.5-6.4,1.6-2.3,0-4.5-.6-6.6-1.6-6.7-3.4-12.8-11-12.8-11l-8.6-12.3s-3.2-4.6-6.2-9.8c-2.6-4.6-4.9-9.7-5.4-11.3-1-3.3-2.7-12.4-2.8-18.9-.1-4.3.6-10.9,1.6-16.8.5-2.9,1.1-5.6,1.6-7.7.9-3.8,3.2-10.1,6.3-15.3,2.1-3.5,4.5-6.2,7.2-8.2,3.1-2.4,7.2-4.9,11.4-6.5,2.1-.8,4.2-1.4,6.1-1.9,5.3-1.1,11.4-1.2,16.4,0,1.9.4,4,1,6.1,1.9,4.2,1.6,8.4,4,11.4,6.5,2.6,2.1,5.1,4.7,7.2,8.2,3.1,5.2,5.4,11.6,6.3,15.3.5,2.1,1.1,4.9,1.6,7.7.9,5.8,1.7,12.4,1.6,16.8"
      />
    </g>
    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M81.2.58L.5,46.08v93.1l80.7,47.6,80.7-45.5V48.18L81.2.58Z"
    />
  </svg>
);

export default IconLoader;